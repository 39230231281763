import React from 'react';
import { theme } from '@tuunetech/tuune-components';

const ANALYTICS = {
  name: 'analytics',
  label: 'Analytics',
  description: `We use some cookies to collect information about how people are using our Services.
                  For example, these cookies allow us to know which pages are visited the most often, how people are moving
                  from one link to another and if they get error messages from certain pages. Overall, these cookies provide
                  us with analytical information about how our Services are performing and how we can improve them.`,
  cookies: ['_ga', '_gid', '_gat*'],
  vendors: [
    {
      name: 'Google',
      url: 'https://policies.google.com/technologies/types?hl=en-US',
      description: 'Google Analytics',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'granted' });
    window.dataLayer.push({
      civic_cookies_analytics: 'consent_given',
      event: 'analytics_consent_given',
    });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'denied' });
    window.dataLayer.push({
      civic_cookies_analytics: 'consent_revoked',
    });
  },
};

const MARKETING = {
  name: 'marketing',
  label: 'Marketing',
  description: `These show us which products you’ve been looking at so we can show you ads you might like later.`,
  cookies: ['fr'],
  vendors: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/policies/cookies/',
      description: 'Facebook',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'granted' });
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_given',
      event: 'marketing_consent_given',
    });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'denied' });
    window.dataLayer.push({
      civic_cookies_marketing: 'consent_revoked',
    });
  },
};

const isDoNotTrackEnabled =
  ((typeof navigator !== 'undefined' && navigator?.doNotTrack) ||
    (typeof window !== 'undefined' && window?.doNotTrack)) === '1';

const optionalCookies = isDoNotTrackEnabled ? [] : [ANALYTICS, MARKETING];

const prismicPreviewCookies =
  process.env.GATSBY_ENV === 'prod'
    ? []
    : ['io.prismic.preview', 'io.prismic.previewSession'];

const necessaryCookies = [...prismicPreviewCookies];

export const CIVIC_COOKIE_CONFIG = {
  apiKey: process.env.GATSBY_CIVIC_API_KEY,
  product: process.env.GATSBY_CIVIC_PRODUCT,
  necessaryCookies,
  optionalCookies: optionalCookies,
  excludedCountries: 'all',
  mode: 'CCPA',

  statement: {
    description: 'For more information visit our',
    name: 'Privacy Policy',
    url: `https://${process.env.GATSBY_LANDING_PAGE_DOMAIN}/privacy-policy`,
    updated: '30/10/2020',
  },
  ccpaConfig: {
    description:
      'This may include the website or our third-party tools processing personal data. In the case of your personal data, you may opt out of such activity by using the buttons below. To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: `https://${process.env.GATSBY_LANDING_PAGE_DOMAIN}/privacy-policy`,
    updated: '30/10/2020',
  },

  initialState: 'notify',
  position: 'LEFT',
  theme: 'DARK',
  notifyDismissButton: false,
  branding: {
    fontFamily: theme.typography.fontFamily,
    fontColor: theme.palette.primary.contrastText,
    notifyFontColor: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    alertBackground: theme.palette.primary.main,
  },
  // locales: [
  //   {
  //     location: ['US-CA', 'CA'],
  //     mode: 'ccpa',
  //   }
  // ]
};

const CIVIC_SCRIPT = (
  <script
    key="civic_source"
    src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
  />
);

export const COOKIE_SCRIPTS =
  process.env.GATSBY_ENV === 'prod'
    ? [
        <script
          key="optimize_script"
          src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_OPTIMIZE_ID}`}
        />,
        <script
          key="gtag_script"
          async
          src={`https://www.googletagmanager.com/gtm.js?id=${process.env.GATSBY_GTM_ID}`}
        />,
        CIVIC_SCRIPT,
        <script key="data_layer_init">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
        `}
        </script>,
      ]
    : [CIVIC_SCRIPT];
