import { createGlobalStyle } from 'styled-components';
import {
  BODY1,
  BODY2,
  BODY3,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
} from '@tuunetech/tuune-components';

export const GlobalStyle = createGlobalStyle`
  ${BODY1}, ${BODY2}, ${BODY3}, ${H1}, ${H2}, ${H3}, ${H4}, ${H5}, ${H6} {
    text-align: ${({ align = 'center' }) => align};
  
    @media ${({ theme }) => theme.media.mobile} {
      text-align: ${({ align = 'left' }) => align};
    }
  }
  
  ${BODY1} {
    color: ${({ color, theme }) =>
      color ? undefined : theme.palette.text.secondary};
  }
`;
