import React, { ReactNode, useCallback, useState } from 'react';
import { ThemeProvider } from '@tuunetech/tuune-components';
import '@fontsource/poppins';
import { PreviewStoreProvider } from 'gatsby-source-prismic';
import CookieWrapper from './cookies/CookieWrapper';
import { GlobalStyle } from './GlobalStyle';

export type SearchContextType = {
  searchQuery: string;
  setSearchQuery: (q: string) => void;
  isSearchActive: boolean;
  setIsSearchActive: (isActive: boolean) => void;
};

export const SearchContext = React.createContext<SearchContextType>(
  {} as SearchContextType,
);

const SearchProvider: React.FC = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);

  const setSearchQ = useCallback((q: string) => {
    setSearchQuery(q);
  }, []);

  const setSActive = useCallback((isActive: boolean) => {
    setIsSearchActive(isActive);
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery: setSearchQ,
        isSearchActive,
        setIsSearchActive: setSActive,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const wrapRootElement: React.FC<{
  element: ReactNode;
}> = ({ element }) => {
  return (
    <CookieWrapper>
      <PreviewStoreProvider>
        <SearchProvider>
          <ThemeProvider>
            <GlobalStyle />
            {element}
          </ThemeProvider>
        </SearchProvider>
      </PreviewStoreProvider>
    </CookieWrapper>
  );
};
